import React, { useEffect, useState } from 'react'
import './style.scss'
import SEO from '../../components/seo'
import { useInView } from 'react-intersection-observer'
// import { Link } from "gatsby"
import TextReveal from '../../components/TextReveal'
import Video from '../../components/Video/Video'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import { motion, AnimatePresence } from "framer-motion"
import MarqueeBox from '../../components/Marquee'
import Slider from 'react-slick'

function Page(props) {
  const { data } = props

  const [refHero, heroInView] = useInView({ triggerOnce: true })
  const [refSides, sidesInView] = useInView({ triggerOnce: true })
  const [refWinner, winnerInView] = useInView({ triggerOnce: true })
  const [refVideo, videoInView] = useInView({ triggerOnce: true })

  const [refGrid, gridInView] = useInView({ triggerOnce: true })
  const [refForm, formInView] = useInView({ triggerOnce: true })
  const [refVenues, venuesInView] = useInView({ triggerOnce: true })
  const [refVenuesSmall, venuesSmallInView] = useInView({ triggerOnce: false })
  const [refCompetition, competitionInView] = useInView({ triggerOnce: true })
  const [refAbout, aboutInView] = useInView({ triggerOnce: true })
  const [refDates, datesInView] = useInView({ triggerOnce: true })
  const [refWagering, wageringInView] = useInView({ triggerOnce: true })
  const [refTv, tvInView] = useInView({ triggerOnce: true })
  const [refChampions, championsInView] = useInView({ triggerOnce: true })

  const [venuesTab, setVenuesTab] = useState(0)
  const [venuesSmallTab, setVenuesSmallTab] = useState(0)

  const [slickPause, setSlickPause] = useState(true)

  const [venuesSmallInteraction, setVenuesSmallInteraction] = useState(false)

  const groupedDates = Array.prototype.reduce.call(data.contentfulTourPage.dates, (acc, current, i) => {
    if (!acc[current.key]) {
      acc[current.key] = [];
    }
    acc[current.key].push({ value: current.value, value2: current.value2, i: i });
    return acc;
  }, {});

  const datesArray = Object.entries(groupedDates).map(([key, value]) => ({ key, value }));

  useEffect(() => {

    let timer = setTimeout(() => {
      setSlickPause(false)
    }, 2000);

    return () => clearTimeout(timer)
  }, [sidesInView])

  useEffect(() => {

    if (venuesSmallInView) {
      let ii = 0
      var interval = setInterval(() => {
        ii++
        if (ii > 2 && !venuesSmallInteraction) {
          setVenuesSmallTab(ii % 3)
        }
      }, 4000)
    }
    else {
      return () => {
        clearInterval(interval)
      }
    }

    return () => {
      clearInterval(interval)
    }
  }, [venuesSmallInteraction, venuesSmallInView])

  const settings = {
    dots: false,
    arrows: false,
    fade: false,
    speed: 600,
    infinite: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    const barTop = document?.getElementById('bar-top')
    const masterHeader = document?.getElementById('master-header-btn')
    const sticky = document?.getElementById('hero-tour')
    if (sticky) {
      sticky.style.paddingTop = barTop.offsetHeight + masterHeader.offsetHeight + 10 + 'px'
    }
  }, [])



  const videoJsOptions = {
    autoplay: false,
    controls: true,
    loop: false,
    poster: "/DATA/IMG/JPG/titlescreen.jpg",
    muted: false,
    sources: [
      {
        src: data?.contentfulTourPage?.video?.file?.url,
        type: 'video/mp4',
      },
    ],
  }

  const videoJsOptionsHero = {
    autoplay: true,
    controls: false,
    loop: true,
    poster: "/DATA/IMG/JPG/titlescreen.jpg",
    muted: true,
    sources: [
      {
        src: "/DATA/VIDEO/tour_video_loop.mp4",
        type: 'video/mp4',
      },
    ],
  }

  function handleClick(e) {
    e.preventDefault()

    if (typeof window !== 'undefined') {
      // let scrollValue = window.pageYOffset + document.querySelector('#form')?.getBoundingClientRect().top
      const anchor = document.querySelector('#form')
      const anchorHeight = Math.round(anchor?.offsetHeight)
      const viewPortHeight = window.innerHeight
      const pageY = window.pageYOffset
      const hashTop = Math.round(document.querySelector('#form')?.getBoundingClientRect().top)

      window.setTimeout(() => window.scrollTo({
        top: pageY + hashTop - (viewPortHeight - anchorHeight) / 2,
        behavior: 'smooth'
      }), 200)
    }
  }


  // On Parent Tab Change
  function onTabChange(i) {
    setVenuesTab(i)
  }



  return (
    <main id="racing-tour">

      <SEO title="1/ST Racing Tour" description="An unprecedented first of its kind, the 1/ST Racing Tour heralds the very best in sport with world class Thoroughbred horse racing, unrivaled competition, elite jockeys, unforgettable experiences, and leading entertainment all at iconic venues. Millions are at stake for horsemen and bettors across more than 50 Grade 1 stakes races coast-to-coast, from Gulfstream Park to Santa Anita and culminating at historic Pimlico race course. New champions will be crowned at extraordinary racing experiences, only at 1/ST." />


      <section ref={refVenuesSmall} className={`section-hero section-venues section-venues-small ${venuesSmallInView ? 'is-inview' : ''} `} >

        <div className="hero-wrap">
          <div className={["hero-video-bg"].join(" ")} role="button" tabIndex={0} >
            <div className="video-expander">
              <div className="aspect-ratio-16-9 blend-overlay">
                <Video {...videoJsOptionsHero} />

                <div className="caption-video landing-video-caption blend-screen blend-soak c0 azabu-global-landing-hero-caption">
                  <div className="special-overlay">
                    <Img
                      fluid={data.imgMask?.childImageSharp.fluid}
                      imgStyle={{ objectFit: 'contain' }}
                      objectPosition='50% 50%'
                      alt={""}
                      loading={'eager'}
                      fadeIn={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TextReveal size="h1" theme="h1 hide-mobile" text={"1/ST<br/>Racing<br/>Tour"} />
          <TextReveal size="h1" theme="h1 show-mobile" text={"1/ST Racing Tour"} />
        </div>

        <div className='column-wrap-left'>
          <div className='left-column'>
            <div className='border border-container flex jc-flex-end'>
              <TextReveal size="h2" theme="h2" text={"<div>Jan <span class='arrow-container'></span> May / 2024</div>"} />
            </div>
            <div className='logos'>
              <div style={{ paddingBottom: "8%" }} className='logo-img aspect-ratio  t'>
                <Img
                  fluid={data.imgLogoNBC?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </div>
              <div style={{ paddingBottom: "12%" }} className='logo-img aspect-ratio  t'>
                <Img
                  fluid={data.imgFanduelLogo?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="column-wrap">
          <div className="column-right">
            <div className="tab-selectors">
              <button className={`button-tab button-gsp ${venuesTab === 0 ? 'current' : ''}`} onClick={() => { setVenuesSmallTab(0); setVenuesSmallInteraction(true); }} onKeyDown={() => { setVenuesSmallTab(0); setVenuesSmallInteraction(true); }} tabIndex={0}>
                <Img
                  fluid={data.imgLogoGulfWhite?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </button>
              <button className={`button-tab button-sap ${venuesTab === 1 ? 'current' : ''}`} onClick={() => { setVenuesSmallTab(1); setVenuesSmallInteraction(true) }} onKeyDown={() => { setVenuesSmallTab(1); setVenuesSmallInteraction(true) }} tabIndex={0}>
                <Img
                  fluid={data.imgLogoSapBlack?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </button>
              <button className={`button-tab button-pimlico ${venuesTab === 2 ? 'current' : ''}`} onClick={() => { setVenuesSmallTab(2); setVenuesSmallInteraction(true) }} onKeyDown={() => { setVenuesSmallTab(2); setVenuesSmallInteraction(true) }} tabIndex={0}>
                <Img
                  fluid={data.imgLogoPreaknessBlack?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </button>
            </div>
            <div className="tab-contents-right">
              <AnimatePresence exitBeforeEnter>
                {venuesSmallTab === 0 ?
                  <motion.div
                    key={0}
                    initial={{ opacity: 0, x: 33 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -33 }}
                    transition={{ duration: 0.35 }}
                  >
                    <div className={`bg-bar gsp`}></div>
                    <div className="color-w gsp">
                      <ul className="list">

                        <h3>GulfstReam Park</h3>
                        {
                          data?.contentfulTourPage?.gulfstreamHero?.map((item, i) => {
                            return (
                              <li key={i} className="row">
                                <span className="item">
                                  {item?.key}
                                </span>
                                <span className="item">
                                  {item?.value}
                                </span>
                                <a href={item?.value2} target="_blank" className={`item btn ${item?.value3?.toLowerCase() === 'on sale now' ? "contrast" : ""}`}>{item?.value3}</a>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </motion.div>
                  : venuesSmallTab === 1 ?
                    <motion.div
                      key={1}
                      initial={{ opacity: 0, x: 33 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -33 }}
                      transition={{ duration: 0.35 }}
                    >
                      <div className={`bg-bar sap`}></div>
                      <div className="color-b sap">
                        <h3>Santa anita Park</h3>
                        <ul className="list">
                          {
                            data?.contentfulTourPage?.santaAnitaHero?.map((item, i) => {
                              return (
                                <li key={i} className="row">
                                  <span className="item">
                                    {item?.key}
                                  </span>
                                  <span className="item">
                                    {item?.value}
                                  </span>
                                  <a href={item?.value2} target="_blank" className={`item btn ${item?.value3?.toLowerCase() === 'on sale now' ? "contrast" : ""}`}>{item?.value3}</a>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </motion.div>
                    : venuesSmallTab === 2 ?
                      <motion.div
                        key={2}
                        initial={{ opacity: 0, x: 33 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -33 }}
                        transition={{ duration: 0.35 }}
                      >
                        <div className={`bg-bar pimlico`}></div>
                        <div className="color-b pimlico">
                          <h3>Pimlico race course</h3>
                          <ul className="list">
                            {
                              data?.contentfulTourPage?.pimlicoHero?.map((item, i) => {
                                return (
                                  <li key={i} className="row">
                                    <span className="item">
                                      {item?.key}
                                    </span>
                                    <span className="item">
                                      {item?.value}
                                    </span>
                                    <a href={item?.value2} target="_blank" className={`item btn ${item?.value3?.toLowerCase() === 'on sale now' ? "contrast" : ""}`}>{item?.value3}</a>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </motion.div>
                      : null}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </section>

      {/*moved wagering section under hero*/}

      <section ref={refWagering} className={` section-wagering padd-bottom-2 ${wageringInView ? 'is-inview' : ''} `} >
        <div id="wagering" className='anchor'></div>
        <div className='grid-12'>
          <div className='span-5 span-12-mobile col-video animate-in-from-left delay-300ms'>
            <Img
              fluid={data.imgWageringPhone?.childImageSharp.fluid}
              imgStyle={{ objectFit: 'cover' }}
              objectPosition='50% 50%'
              alt={""}
            />
          </div>
          <div className='span-7 span-12-mobile right-side padd-3 padd-bottom-off animate-in-from-right delay-300ms'>
            <div className='heading-reveal c5 padd'>
              <TextReveal size={"h2"} text={"WAGERING"} ></TextReveal>
            </div>
            {/*<h4>The 1/ST Racing Tour provides the best<br />
              wagering opportunities in the country </h4>*/}
            <div className='grid-12'>
              <div className='span-7 span-12-mobile'> {/* was span-8*/}
              <h4 style={{ marginTop: "0.5em" }}>The 1/ST Racing Tour provides the best
              wagering opportunities in the country </h4>
                <p className='content'>Coordinated post times to provide the best racing action every 20 minutes </p>
                <p className='content'>Six 1/ST Betting Challenges across the 1/ST RACING Tour</p>
                <p className='content'>1/ST Coast to Coast Pick 5 wagers featuring best races from Gulfstream Park and Santa Anita Park. $1 Minimum. Bettor friendly, 15% takeout</p>
                {/* <a href={"#"} className={`btn`}>FREE DRF PP'S</a> */}
                
                <span href={''} className={`btn drf`}><a href="https://promos.drf.com/Coast-to-Coast-Pick5" style={{ color: "#fff" }}>FREE DRF PP'S</a></span>
                {/*<span href={''} className={`btn sap-picks`}><a href="https://www.santaanita.com/wagering#expert-picks">SANTA ANITA PARK EXPERT PICKS</a></span>
                <span href={''} className={`btn gsp-picks`}><a href="https://gulfstreampark.com/wagering#expert-picks" style={{ color: "#fff" }}>GULFSTREAM PARK EXPERT PICKS</a></span>*/}
                
              </div>
              <div className='span-4 span-12-mobile logo'> {/* was span-5*/}
              {/*<Img
                  fluid={data.imgPick5?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              <Img
                  fluid={data.imgSappromo2?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  alt={""}
              />*/}
              </div>
            </div>

          </div>
        </div>
      </section>

      <section id="section-winner" style={{ overflow: "hidden" }} ref={refWinner} className={`section-winner padd-top padd-bottom-4 ${winnerInView ? 'is-inview' : ''} `} >
        <div className='flex'>
          <div style={{ zIndex: "10" }} className='left-side animate-in-from-left delay-400ms padd-top-4'>
            <div className='container padd-top-4'>
              <div className='logos-container c5 padd-2'>
                <div style={{ paddingBottom: "12%" }} className='logo-img aspect-ratio  t'>
                  <Img
                    fluid={data.imgLogosStripWhite?.childImageSharp.fluid}
                    imgStyle={{ objectFit: 'contain' }}
                    objectPosition='50% 50%'
                    alt={""}
                  />
                </div>
              </div>
              <div className='padd-4'>

                <div className='content'>
                  <div className="" dangerouslySetInnerHTML={{ __html: data?.contentfulTourPage?.about?.childMarkdownRemark?.html }} />
                </div>
              </div>
            </div>
          </div>
          <div className='right-side animate-in-from-right delay-400ms'>
            <div className='img-container'>
              <div style={{ paddingBottom: "120%" }} className='aspect-ratio'>
                <Img
                  fluid={data.imgWinner?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </div>
            </div>
            <div className='marquee-container'>
              <MarqueeBox copy="SPEED AND SPECTACLE" rows={18} mobileRows={12} reverse={false} speed={[123, 42, 12, 53, 123, 230, 2, 41, 100, 22, 32, 63, 111, 98, 15, 76, 117, 6]} />
            </div>
          </div>
        </div>
      </section>

      <section ref={refVenues} className={`section-venues ${venuesInView ? 'is-inview' : ''} `} >
        <div className="column-wrap">
          <div className="column-left">
            <div className="tab-contents-left">
              <AnimatePresence exitBeforeEnter>
                {venuesTab === 0 ?
                  <motion.div
                    key={0}
                    initial={{ opacity: 0, x: 10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -10, transition: { duration: 0.45, delay: 0 } }}
                    transition={{ duration: 0.5, delay: 0.125 }}
                  >
                    <div className="img-container">
                      <div className="aspect-ratio">
                        <Img
                          fluid={data.imgTabGulf?.childImageSharp.fluid}
                          imgStyle={{ objectFit: 'cover' }}
                          objectPosition='50% 50%'
                          alt={"Gulfstream tab image"}
                        />
                        <div className="logo-corner">
                          <motion.div
                            key={0}
                            initial={{ opacity: 0, x: 33 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -33 }}
                            transition={{ duration: 0.25, delay: 0.325 }}
                          >
                            <div className="aspect-ratio">
                              <Img
                                fluid={data.imgTabGulfLogo?.childImageSharp.fluid}
                                imgStyle={{ objectFit: 'contain' }}
                                objectPosition='50% 50%'
                                alt={"Gulfstream tab logo"}
                              />
                            </div>
                          </motion.div>
                        </div>
                      </div>
                    </div>
                    <div className="title-box-wrapper">
                      <div className="title-box gsp">
                        <div className="line-top">
                          <TextReveal size={"h2"} text={"Gulfstream"} />
                        </div>
                        <div className="line-bottom">
                          <div className="line-left">
                            <TextReveal size={"h2"} text={"Park"} />
                          </div>
                          <div className="line-right">
                            <TextReveal size={"p"} text={"901 FEDERAL HWY.<br/>HALLANDALE BEACH, FL 33009"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  : venuesTab === 1 ?
                    <motion.div
                      key={1}
                      initial={{ opacity: 0, x: 10 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -10, transition: { duration: 0.45, delay: 0 } }}
                      transition={{ duration: 0.5, delay: 0.125 }}
                    >
                      <div className="img-container">
                        <div className="aspect-ratio">
                          <Img
                            fluid={data.imgTabSap?.childImageSharp.fluid}
                            imgStyle={{ objectFit: 'cover' }}
                            objectPosition='50% 50%'
                            alt={"Santa Anita tab image"}
                          />
                          <div className="logo-corner">
                            <motion.div
                              key={0}
                              initial={{ opacity: 0, x: 33 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: -33 }}
                              transition={{ duration: 0.25, delay: 0.325 }}
                            >
                              <div className="aspect-ratio">
                                <Img
                                  fluid={data.imgTabSapLogo?.childImageSharp.fluid}
                                  imgStyle={{ objectFit: 'contain' }}
                                  objectPosition='50% 50%'
                                  alt={"Santa Anita tab logo"}
                                />
                              </div>
                            </motion.div>
                          </div>
                        </div>
                      </div>
                      <div className="title-box-wrapper">
                        <div className="title-box sap">
                          <div className="line-top">
                            <TextReveal size={"h2"} text={"Santa Anita"} />
                          </div>
                          <div className="line-bottom">
                            <div className="line-left">
                              <TextReveal size={"h2"} text={"Park"} />
                            </div>
                            <div className="line-right">
                              <TextReveal size={"p"} text={"285 W Huntington Dr.<br/>Arcadia, CA 91007"} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                    : venuesTab === 2 ?
                      <motion.div
                        key={2}
                        initial={{ opacity: 0, x: 10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10, transition: { duration: 0.45, delay: 0 } }}
                        transition={{ duration: 0.5, delay: 0.125 }}
                      >
                        <div className="img-container">
                          <div className="aspect-ratio">
                            <Img
                              fluid={data.imgTabPimlico?.childImageSharp.fluid}
                              imgStyle={{ objectFit: 'cover' }}
                              objectPosition='50% 50%'
                              alt={"Pimlico tab image"}
                            />
                            <div className="logo-corner">
                              <motion.div
                                key={0}
                                initial={{ opacity: 0, x: 33 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -33 }}
                                transition={{ duration: 0.25, delay: 0.325 }}
                              >
                                <div className="aspect-ratio">
                                  <Img
                                    fluid={data.imgTabPimlicoLogo?.childImageSharp.fluid}
                                    imgStyle={{ objectFit: 'contain' }}
                                    objectPosition='0% 0%'
                                    alt={"Pimlico tab logo"}
                                  />
                                </div>
                              </motion.div>
                            </div>
                          </div>
                        </div>
                        <div className="title-box-wrapper">
                          <div className="title-box pimlico">
                            <div className="line-top">
                              <TextReveal size={"h2"} text={"Pimlico Race"} />
                            </div>
                            <div className="line-bottom">
                              <div className="line-left">
                                <TextReveal size={"h2"} text={"Course"} />
                              </div>
                              <div className="line-right">
                                <TextReveal size={"p"} text={"5201 Park Heights Ave.<br/>Baltimore, MD 21215"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                      : null}
              </AnimatePresence>
            </div>
          </div>
          <div className="column-right">
            <TextReveal size={"h2"} text={"Three <mark>Iconic</mark><br/>Venues"} />
            <div className="tab-selectors">
              <button className={`button-tab button-gsp ${venuesTab === 0 ? 'current' : ''}`} onClick={() => onTabChange(0)} onKeyDown={() => onTabChange(0)} tabIndex={0}>
                <Img
                  fluid={data.imgLogoGulfWhite?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </button>
              <button className={`button-tab button-sap ${venuesTab === 1 ? 'current' : ''}`} onClick={() => onTabChange(1)} onKeyDown={() => onTabChange(1)} tabIndex={0}>
                <Img
                  fluid={data.imgLogoSapBlack?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </button>
              <button className={`button-tab button-pimlico ${venuesTab === 2 ? 'current' : ''}`} onClick={() => onTabChange(2)} onKeyDown={() => onTabChange(2)} tabIndex={0}>
                <Img
                  fluid={data.imgLogoPreaknessBlack?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </button>
            </div>
            <div className="tab-contents-right">
              <AnimatePresence exitBeforeEnter>
                {venuesTab === 0 ?
                  <motion.div
                    key={0}
                    initial={{ opacity: 0, x: 33 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -33 }}
                    transition={{ duration: 0.35 }}
                  >
                    <div className={`bg-bar gsp`}></div>
                    <div className="color-w gsp">
                      {/* {
                        data?.contentfulTourPage?.gulfstreamPacks?.map((item, i) => {
                          return (
                            <div key={i}>
                              <h3>{item?.key}</h3>
                              <ul className="list">
                                <li className="row">
                                  <span className="item">
                                    {item?.value}
                                  </span>
                                  <a href={item?.value2} className="item btn contrast">{item?.value3}</a>
                                </li>
                              </ul>
                            </div>
                          )
                        })
                      } */}
                      <h3>GulfstReam Events</h3>
                      <ul className="list with-highlights">
                        {
                          data?.contentfulTourPage?.gulfstreamSingleEvents?.map((item, i) => {
                            return (
                              <li key={i} className="row">
                                <span className="item">
                                  {item?.key}
                            </span>
                            
                                <span className="item">
                                  {item?.value}
                                </span>
                                <a href={item?.value2} className={`item btn ${item?.value3?.toLowerCase() === 'on sale now' ? "contrast" : ""}`}>{item?.value3}</a>
                                <span className="item">
                                  {item?.image}
                                  {item?.value4}
                                </span>
                              </li>
                              
                            )
                          })
                        }
                      </ul>
                    </div>
                  </motion.div>
                  : venuesTab === 1 ?
                    <motion.div
                      key={1}
                      initial={{ opacity: 0, x: 33 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -33 }}
                      transition={{ duration: 0.35 }}
                    >
                      <div className={`bg-bar sap`}></div>
                      <div className="color-b sap">
                        {/* {
                          data?.contentfulTourPage?.santaAnitaPacks?.map((item, i) => {
                            return (
                              <div key={i}>
                                <h3>{item?.key}</h3>
                                <ul className="list">
                                  <li className="row">
                                    <span className="item">
                                      {item?.value}
                                    </span>
                                    <a href={item?.value2} className="item btn contrast">{item?.value3}</a>
                                  </li>
                                </ul>
                              </div>
                            )
                          })
                        } */}
                        <h3>Santa Anita Events</h3>
                        <ul className="list with-darklights">
                          {
                            data?.contentfulTourPage?.santaAnitaSingleEvents?.map((item, i) => {
                              return (
                                <li key={i} className="row">
                                  <span className="item">
                                    {item?.key}
                              </span>
                                  <span className="item">
                                    {item?.value}
                                  </span>
                                  <a href={item?.value2} className={`item btn ${item?.value3?.toLowerCase() === 'on sale now' ? "contrast" : ""}`}>{item?.value3}</a>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </motion.div>
                    : venuesTab === 2 ?
                      <motion.div
                        key={2}
                        initial={{ opacity: 0, x: 33 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -33 }}
                        transition={{ duration: 0.35 }}
                      >
                        <div className={`bg-bar pimlico`}></div>
                        <div className="color-b pimlico">
                          {
                            data?.contentfulTourPage?.pimlicoPacks?.map((item, i) => {
                              return (
                                <div key={i}>
                                  <h3>{item?.key}</h3>
                                  <ul className="list">
                                    <li className="row">
                                      <span className="item">
                                        <h4>{item?.value}</h4>
                                      </span>
                                      <a href={item?.value2} className="item btn contrast">{item?.value3}</a>
                                    </li>
                                  </ul>
                                </div>
                              )
                            })
                          }

                          <h3>Single Events</h3>
                          <ul className="list with-darklights">
                            {
                              data?.contentfulTourPage?.pimlicoSingleEvents?.map((item, i) => {
                                return (
                                  <li key={i} className="row">
                                    <span className="item">
                                      {item?.key}
                                </span>
                                    <span className="item">
                                      {item?.value}
                                    </span>
                                    <a href={item?.value2} className={`item btn ${item?.value3?.toLowerCase() === 'on sale now' ? "contrast" : ""}`}>{item?.value3}</a>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </motion.div>
                      : null}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </section>


      <section id="section-sides" ref={refSides} className={`section-sides ${sidesInView ? 'is-inview' : ''} `} >
        <div className='positioner animate-in-from-left delay-375ms'>
          <div className='heading '>
            <div className='heading-top  c5 padd'>
              <TextReveal size={"h2"} text={"Where"} ></TextReveal>
            </div>
            <div className='heading-bottom c5 padd'>
              <TextReveal size={"h2"} text={"Speed"} ></TextReveal>
            </div>
          </div>
        </div>
        <div className='main-container'>
          <div className='grid-12'>
            <div className='span-6 animate-in-from-left delay-375ms'>
              <div className="left-image scale-left ">
                <Slider {...settings}>
                  {
                    data?.contentfulTourPage?.slidesLeft?.map((slide, i) => {
                      return (
                        <div key={"slideL-" + i} className='aspect-ratio'>
                          <Img
                            fluid={slide?.fluid}
                            imgStyle={{ objectFit: 'cover' }}
                            objectPosition='50% 50%'
                            alt={slide?.title}
                          />
                        </div>
                      )
                    })
                  }
                </Slider>
              </div>
            </div>
            <div className='span-6 space-top animate-in-from-right delay-750ms'>
              <div className='bottom-positioner'>
                <div className='heading'>
                  <div className='heading-top c0 padd'>
                    <TextReveal size={"h2"} text={"Meets"}  ></TextReveal>
                  </div>
                  <div className='heading-bottom c0 padd'>
                    <TextReveal size={"h2"} text={"Spectacle"} ></TextReveal>
                  </div>
                </div>
              </div>
              <div className="right-image scale-right">

                <Slider	{...{ ...settings, slickPause: slickPause }}>
                  {
                    data?.contentfulTourPage?.slidesRight?.map((slide, i) => {
                      return (
                        <div key={"slideR-" + i} className='aspect-ratio'>
                          <Img
                            fluid={slide?.fluid}
                            imgStyle={{ objectFit: 'cover' }}
                            objectPosition='50% 50%'
                            alt={slide?.title}
                          />
                        </div>
                      )
                    })
                  }
                </Slider>
              </div>
            </div>

          </div>
        </div>
      </section>



      <section id="section-video" ref={refVideo} className={`section-video padd-top padd-bottom  ${videoInView ? 'is-inview' : ''} `} >
        <div className=' animate-in-from-right delay-300ms'>
          <div className='marquee-container-top padd-top-4'>
            <MarqueeBox copy="SPEED AND SPECTACLE" rows={15} mobileRows={3} reverse={false} speed={[123, 42, 12, 53, 123, 230, 2, 41, 100, 22, 32, 63, 111, 98, 15, 76, 117, 6]} />
          </div>
          <div className='padd-4 spacing'>
            <div className={["hero-video-bg"].join(" ")} role="button" tabIndex={0} >
              <div className="video-expander">
                <div className="aspect-ratio-16-9">
                  <Video {...videoJsOptions} />
                  <div className="decorative-arrow"></div>
                </div>
              </div>
            </div>
          </div>
          <div className='marquee-container-bottom padd-bottom-4'>
            <MarqueeBox copy="SPEED AND SPECTACLE" rows={15} mobileRows={3} reverse={false} speed={[123, 42, 12, 53, 123, 230, 2, 41, 100, 22, 32, 63, 111, 98, 15, 76, 117, 6]} />
          </div>
        </div>
      </section>


      {/*<section ref={refWagering} className={` section-wagering padd-bottom-2 ${wageringInView ? 'is-inview' : ''} `} >
        <div id="wagering" className='anchor'></div>
        <div className='grid-12'>
          <div className='span-5 span-12-mobile col-video animate-in-from-left delay-300ms'>
            <Img
              fluid={data.imgWageringPhone?.childImageSharp.fluid}
              imgStyle={{ objectFit: 'cover' }}
              objectPosition='50% 50%'
              alt={""}
            />
          </div>
          <div className='span-7 span-12-mobile right-side padd-3 padd-bottom-off animate-in-from-right delay-300ms'>
            <div className='heading-reveal c5 padd'>
              <TextReveal size={"h2"} text={"WAGERING"} ></TextReveal>
            </div>
            <h4>The 1/ST Racing Tour provides the best<br />
              wagering opportunities in the country </h4>
            <div className='grid-12'>
              <div className='span-8 span-12-mobile'>
                <p className='content'>Coordinated post times to provide the best racing action every 20 minutes </p>
                <p className='content'>Six 1/ST Betting Challenges across the 1/ST RACING Tour</p>
                <p className='content'>1/ST Coast to Coast Pick 5 wagers featuring best races from Gulfstream Park and Santa Anita Park. $1 Minimum. Bettor friendly, 15% takeout</p>
                {/* <a href={"#"} className={`btn`}>FREE DRF PP'S</a> 
                <span href={''} className={`btn`}> FREE DRF PP'S COMING SOON</span>
              </div>
              <div className='span-4 span-12-mobile logo'>
                <Img
                  fluid={data.imgPick5?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </div>
            </div>

          </div>
        </div>
                </section>*/}


      {/* <section id="hero-tour" ref={refHero} className={`section-hero text-center ${heroInView ? 'is-inview' : ''} `} >
          <div className='hero-tour-main-wrap'>
            <div className='hero-tour-bg left-bg'>
              <div className="positioner-img-1">
                <div className="aspect-ratio">
                  <img src={'/DATA/IMG/JPG/img-1.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
                </div>
                <div className="aspect-ratio">
                  <img src={'/DATA/IMG/JPG/img-2.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
                </div>
              </div>
              <div className="positioner-img-2">
                <div className="aspect-ratio">
                  <img src={'/DATA/IMG/JPG/img-2.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
                </div>
              </div>
              <div className="positioner-img-3">
                <div className="aspect-ratio">
                  <img src={'/DATA/IMG/JPG/img-3.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
                </div>
              </div>
            </div>
            <div className='hero-tour-bg right-bg'>
              <div className="positioner-img-4">
                <div className="aspect-ratio">
                  <img src={'/DATA/IMG/JPG/img-4.jpg'} className={["img-cover", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing" />
                </div>
              </div>
            </div>
            <div className='hero-tour-wrap text-center'>
              <a className='uppercase form-link' href="#form" onClick={(e) => handleClick(e)}>
                <TextReveal size={"h5"} theme="margin-0" text={"Click Here For"} />
                <TextReveal size={"h5"} theme="margin-0 fancy-font" text={"<mark>1/ST Access</mark>"} />
              </a>
              <div className="hero-tour-wrap-intro">
                <div className='hero-logo'>
                  <div className='aspect-ratio-16-5'>
                    <img src={'/DATA/IMG/PNG/racing_tour_logo.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="1st Racing Tour Logo" />
                  </div>
                </div>
                <TextReveal size={"h1"} text={"1/ST Racing"} />
                <TextReveal size={"h1"} theme="fancy-font contrast" text={"tour"} />
                <h4 className='animate-in-from-bottom delay-250ms'>2023</h4>
                <div className='flex-dates'>
                  <h4 className='flex-heading animate-in-from-bottom delay-375ms'><span>January 28</span><span>Pegasus World Cup</span></h4>
                  <h4 className='flex-heading animate-in-from-bottom delay-500ms'><span>February 4</span><span>Holy Bull & Robert B. Lewis</span></h4>
                  <h4 className='flex-heading animate-in-from-bottom delay-625ms'><span>March 4</span><span>Fountain of Youth & Big 'Cap</span></h4>
                  <h4 className='flex-heading animate-in-from-bottom delay-750ms'><span>April 1</span><span>Florida Derby</span></h4>
                  <h4 className='flex-heading animate-in-from-bottom delay-875ms'><span>April 8</span> <span>Santa Anita Derby</span></h4>
                  <h4 className='flex-heading animate-in-from-bottom delay-1000ms'><span>May 20</span> <span>Preakness 148</span></h4>
                </div>
              </div>
              <div className='hero-tour-wrap-logos grid-12 padd-top'>
                <div className='span-6'></div>
                <div className='span-6 span-12-mobile c0 grid-12'>

                  <a href="https://www.gulfstreampark.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                    <div className="logo-size-limiter">
                      <div className='aspect-ratio-16-9'>
                        <img src={'/DATA/IMG/PNG/1.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Gulfstream Park Logo" />
                      </div>
                    </div>
                    <p className='venue-title blue'>Gulfstream <br /> Park</p>
                  </a>

                  <a href="https://www.santaanita.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                    <div className="logo-size-limiter sap">
                      <div className='aspect-ratio-16-9'>
                        <img src={'/DATA/IMG/PNG/2.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Santa Anita Park Logo" />
                      </div>
                    </div>
                    <p className='venue-title green'>Santa Anita <br /> Park</p>
                  </a>

                  <a href="https://www.laurelpark.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                    <div className="logo-size-limiter">
                      <div className='aspect-ratio-16-9'>
                        <img src={'/DATA/IMG/PNG/3.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Maryland Jockey Club Logo" />
                      </div>
                    </div>
                    <p className='venue-title red'>Maryland <br /> Jockey Club</p>
                  </a>

                  <a href="https://www.goldengatefields.com" target="_blank" rel="noreferrer noopener" className='nostrike span-3 padd-2 single-logo'>
                    <div className="logo-size-limiter">
                      <div className='aspect-ratio-16-9'>
                        <img src={'/DATA/IMG/PNG/4.png'} className={["img-contain", "lazyload", "fade", heroInView ? "lazyloaded" : null].join(" ")} alt="Golden Gate Fields Logo" />
                      </div>
                    </div>
                    <p className='venue-title yellow'>Golden Gate <br /> Field</p>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </section> */}

      {/* <section ref={refGrid} className={`section-grid padd-top padd-bottom text-center grid-12 ${gridInView ? 'is-inview' : ''} `} >
          <div className='photo-grid span-12'>

            <div className='single-img padd-2'>
              <div className='aspect-ratio'>
                <img src={'/DATA/IMG/JPG/grid-1.jpg'} className={["delay-250ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="Best Experiences" />
              </div>
              <h6 className='animate-in-from-bottom delay-250ms'>Best In-venue Experiences</h6>
            </div>

            <div className='single-img padd-2'>
              <div className='aspect-ratio'>
                <img src={'/DATA/IMG/JPG/grid-2.jpg'} className={["delay-375ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="Wagering Tournament" />
              </div>
              <h6 className='animate-in-from-bottom delay-375ms'>1/ST Bet Wagering Tournament</h6>
            </div>

            <div className='single-img padd-2'>
              <div className='aspect-ratio'>
                <img src={'/DATA/IMG/JPG/grid-3.jpg'} className={["delay-500ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="50+ Stakes Races" />
              </div>
              <h6 className='animate-in-from-bottom delay-500ms'>50+ Stakes Races</h6>
            </div>

            <div className='single-img padd-2'>
              <div className='aspect-ratio'>
                <img src={'/DATA/IMG/JPG/grid-4.jpg'} className={["delay-625ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="Bonus Incentives" />
              </div>
              <h6 className='animate-in-from-bottom delay-625ms'>New Owner/ Trainer Bonus Incentives</h6>
            </div>

            <div className='single-img padd-2'>
              <div className='aspect-ratio'>
                <img src={'/DATA/IMG/JPG/grid-5.jpg'} className={["delay-750ms", "img-cover", "lazyload", "fade", gridInView ? "lazyloaded" : null].join(" ")} alt="3 World Class Venues" />
              </div>
              <h6 className='animate-in-from-bottom delay-750ms'>3 World Class Venues</h6>
            </div>

          </div>
        </section> */}


      <section ref={refCompetition} className={`section-competition ${competitionInView ? 'is-inview' : ''} `} >
        <div className="bg">
          <Img
            fluid={data.imgRacingBG?.childImageSharp.fluid}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            alt={""}
          />
        </div>
        <div className="sport">
          <Img
            fluid={data.imgSport?.childImageSharp.fluid}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            alt={""}
          />
        </div>
        <div className="mirage-text">
          <div className="mirage"><TextReveal size={"h1"} theme="h1" text={"Unrivaled"} /></div>
          <div className="mirage"><TextReveal size={"h1"} theme="h1" text={"Competition"} /></div>
        </div>
        <div className="block-dates">
          {
            datesArray?.map((item, i) => {
              return (
                <div className={`block-group group-${i + 1}`}>
                  {item.value.map((row, j) => {
                    return (
                      <div key={j} className={`block-date ${row.value2.toLowerCase() === 'santa anita park' ? 'grn' : ''} ${row.value2.toLowerCase() === 'gulfstream park' ? 'blu' : ''}  ${row.value2.toLowerCase() === 'pimlico' ? 'ylw' : ''} block-${row.i}`}>
                        <div className="date">
                          <h3>{item.key}</h3>
                        </div>
                        <div className="info">
                          <h4 className="title">{row.value}</h4>
                          <h5 className="subtitle">{row.value2}</h5>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })
          }

          {/* <div className="block-group group-1">
            <div className="block-date blu block-1">
              <div className="date">
                <h3>01.28</h3>
              </div>
              <div className="info">
                <h4 className="title">Pegasus World Cup</h4>
                <h5 className="subtitle">Gulfstream Park</h5>
              </div>
            </div>
          </div>
          <div className="block-group group-2">
            <div className="block-date blu block-2">
              <div className="date">
                <h3>02.04</h3>
              </div>
              <div className="info">
                <h4 className="title">Holy Bull</h4>
                <h5 className="subtitle">Gulfstream Park</h5>
              </div>
            </div>
            <div className="block-date grn block-3">
              <div className="date">
                <h3>02.04</h3>
              </div>
              <div className="info">
                <h4 className="title">Robert B. Lewis</h4>
                <h5 className="subtitle">Santa Anita Park</h5>
              </div>
            </div>
          </div>
          <div className="block-group group-3">
            <div className="block-date grn block-4">
              <div className="date">
                <h3>03.04</h3>
              </div>
              <div className="info">
                <h4 className="title">Big Cap</h4>
                <h5 className="subtitle">Santa Anita Park</h5>
              </div>
            </div>
            <div className="block-date blu block-5">
              <div className="date">
                <h3>03.04</h3>
              </div>
              <div className="info">
                <h4 className="title">fountain of Youth</h4>
                <h5 className="subtitle">Gulfstream Park</h5>
              </div>
            </div>
          </div>
          <div className="block-group group-4">
            <div className="block-date blu block-6">
              <div className="date">
                <h3>04.01</h3>
              </div>
              <div className="info">
                <h4 className="title">Florida Derby</h4>
                <h5 className="subtitle">Gulfstream Park</h5>
              </div>
            </div>
          </div>
          <div className="block-group group-5">
            <div className="block-date grn block-7">
              <div className="date">
                <h3>04.08</h3>
              </div>
              <div className="info">
                <h4 className="title">Santa Anita Derby</h4>
                <h5 className="subtitle">Santa Anita Park</h5>
              </div>
            </div>
          </div>
          <div className="block-group group-6">
            <div className="block-date ylw block-8">
              <div className="date">
                <h3>05.20</h3>
              </div>
              <div className="info">
                <h4 className="title">PREAKNESS 148</h4>
                <h5 className="subtitle">Pimlico</h5>
              </div>
            </div>
          </div> */}
          {/* <div className="block-group group-7">
              <div className="block-date ylw block-9">
                <div className="date">
                  <h3>--.--</h3>
                </div>
                <div className="info">
                  <h4 className="title">TEST TEST</h4>
                  <h5 className="subtitle">Test</h5>
                </div>
              </div>
              <div className="block-date ylw block-10">
                <div className="date">
                  <h3>--.--</h3>
                </div>
                <div className="info">
                  <h4 className="title">TEST TEST</h4>
                  <h5 className="subtitle">Test</h5>
                </div>
              </div>
            </div>
            <div className="block-group group-8">
              <div className="block-date ylw block-11">
                <div className="date">
                  <h3>--.--</h3>
                </div>
                <div className="info">
                  <h4 className="title">TEST TEST</h4>
                  <h5 className="subtitle">Test</h5>
                </div>
              </div>
              <div className="block-date ylw block-12">
                <div className="date">
                  <h3>--.--</h3>
                </div>
                <div className="info">
                  <h4 className="title">TEST TEST</h4>
                  <h5 className="subtitle">Test</h5>
                </div>
              </div>
            </div>
            <div className="block-group group-9">
              <div className="block-date ylw block-13">
                <div className="date">
                  <h3>--.--</h3>
                </div>
                <div className="info">
                  <h4 className="title">TEST TEST</h4>
                  <h5 className="subtitle">Test</h5>
                </div>
              </div>
            </div> */}
        </div>
      </section>

      {/*<section ref={refChampions} className={`section-champions padd-top-4 padd-bottom-4 ${championsInView ? 'is-inview' : ''} `} >
        <div id="champions" className='anchor'></div>
        <div className='grid-12'>
          <div className='span-5 left-side span-12-mobile padd-3 padd-top-off padd-bottom-off animate-in-from-right delay-300ms'>
            <div className='heading-reveal'>
              <TextReveal size={"h2"} text={"Crowning<br/>Industry<br/><mark>Champions...</mark>"} />
            </div>
            <h4>At Gulfstream Park<br />
              and Santa Anita Park  </h4>
            <h4 className='border-bottom'>Owners</h4>
            <p>
              Receive a customized 1/ST Racing Tour Suite for 1-year featuring the owners silks, colors, photos of 1/ST Racing Tour highlights.
            </p>
            <h4 className='border-bottom'>TRAINERS</h4>
            <p>
              $50,000 cash bonus for the Trainers with the most points accumulated during Stakes races on Tour days.
            </p>
          </div>
          <div className='span-7 right-side span-12-mobile padd-right-3 animate-in-from-left delay-300ms'>
            <div style={{ paddingBottom: "60%" }} className='aspect-ratio'>
              <Img
                fluid={data.imgChampions?.childImageSharp.fluid}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                alt={""}
              />
            </div>
          </div>
        </div>
          </section>*/}

      {/*<section ref={refTv} className={`section-tv padd-bottom-2 ${tvInView ? 'is-inview' : ''} `} >
        <div id="tv" className='anchor'></div>
        <div className='grid-12'>
          <div className='span-5 padd-top-2 padd-bottom-2 span-12-mobile left-side animate-in-from-left delay-300ms'>
            <div className='padd-3'>
              <div style={{ paddingBottom: "12%" }} className='logo-img aspect-ratio  t'>
                <Img
                  fluid={data.imgLogosStripBlack?.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  objectPosition='50% 50%'
                  alt={""}
                />
              </div>
              <h2>National Tv</h2>
              <div className='flex'>
                <div style={{ paddingBottom: "12%" }} className='logo-nbc aspect-ratio t'>
                  <Img
                    fluid={data.imgLogoNBC?.childImageSharp.fluid}
                    imgStyle={{ objectFit: 'contain' }}
                    objectPosition='50% 50%'
                    alt={""}
                  />
                </div>
                <p>The 1/ST Racing Tour will be broadcast nationally
                  with our partners from NBC Universal.</p>
              </div>
            </div>
            <div className='img-horses'>
              <Img
                fluid={data.imgTv?.childImageSharp.fluid}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                alt={""}
              />
            </div>
          </div>
          <div className='span-7 span-12-mobile right-side padd-3 c5 animate-in-from-right delay-300ms'>
            <div className='events-container'>
              <div className='row'>
                <div className='date'>
                  <h3 className='heading'>JAN 28</h3>
                </div>
                <div className='event'>
                  <h3 className='heading'>Pegasus World Cup</h3>
                  <p>
                    Featuring 8 Stakes Races from Gulfstream Park
                    NBC 4:30pm - 6:00pm (EST)
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='date'>
                  <h3 className='heading'>FEB 04</h3>
                </div>
                <div className='event'>
                  <h3 className='heading'>1/st Saturday in february</h3>
                  <p>
                    Featuring 9 Stakes races including Robert B. Lewis
                    and Holy Bull (Triple Crown Preps)
                    NBC 4:00pm - 6:00pm (EST)
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='date'>
                  <h3 className='heading'>MAR 04</h3>
                </div>
                <div className='event'>
                  <h3 className='heading'>1/st Saturday in MARCH</h3>
                  <p>
                    Featuring 13 Stakes races including and Santa Anita Handicap
                    plus San Felipe and Fountain of Youth (Triple Crown Preps)
                    NBC 4:00pm - 6:00pm (EST)
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='date'>
                  <h3 className='heading'>APR 01</h3>
                </div>
                <div className='event'>
                  <h3 className='heading'>1/st Saturday in APRIL</h3>
                  <p>
                    Featuring 12 stakes races highlighted by Florida Derby (Triple Crown Prep)
                    and Santa Anita Oaks from Santa Anita Park
                    NBC 6:00pm - 7:00pm (EST)
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='date'>
                  <h3 className='heading'>APR 08</h3>
                </div>
                <div className='event'>
                  <h3 className='heading'>SANTA ANITA DERBY</h3>
                  <p>
                    Featuring 7 stakes races from Santa Anita
                    NBC 4:30pm - 6:00pm (EST)
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='date'>
                  <h3 className='heading'>MAY 20</h3>
                </div>
                <div className='event'>
                  <h3 className='heading'>PREAKNESS STAKES DAY</h3>
                  <p>
                    Featuring 11 stakes race from Pimlico
                    NBC Time TBD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>*/}

      <section ref={refForm} className={` section-form ${formInView ? 'is-inview' : ''} `} >
        <div id="form" className='anchor'></div>
        <div className='grid-12'>
          <div className='span-4 col-video'>
            <Img
              fluid={data.imgLogoRacingTourWhite?.childImageSharp.fluid}
              imgStyle={{ objectFit: 'cover' }}
              objectPosition='50% 50%'
              alt={""}
            />
            <div className='marquee-container'>
              <MarqueeBox copy="SPEED AND SPECTACLE" rows={14} mobileRows={4} reverse={false} classes={"c4"} speed={[123, 42, 12, 53, 123, 230, 2, 41, 100, 22, 22, 150, 91]} />
            </div>
          </div>
          <div className='span-8 col-form padd-3 padd-bottom-off uppercase'>
            <TextReveal text={"Get <mark>1/ST Access</mark>"} />
            <p className='content'>Exclusive Ticket Offerings </p>
            <p className='content'>Premium Seating </p>
            <p className='content'>Priority Access </p>
            <p className='content'>Season Ticket Packages </p>
            <p className='content'>Vip Hospitality </p>
            <p className='content'>Access To Pre-race fan events</p>
            <iframe
              id="form-tour"
              src="https://cloud.experience.1st.com/racing_tour_2023"
              frameBorder="0"
              style={{ order: '100', backgroundColor: 'transparent', width: '100vw', maxWidth: '100%', padding: '1em 0', margin: '0 auto', minHeight: '285px', height: '285px' }}
              title="Contact Form"
            />
          </div>
        </div>
      </section>

      {/* 
        <section ref={refAbout} className={`section-about ${aboutInView ? 'is-inview' : ''} `} >
          <div className='grid-12 padd-bottom'>
            <div className='span-3  green-bg'></div>
            <div className='span-6 span-9-mobile padd content padd-top-3 padd-bottom-3'>
              <TextReveal size="h2" text={"About"} />
              <p>An unprecedented first of its kind, the 1/ST Racing Tour heralds the very best in sport with world class Thoroughbred horse racing, unrivaled competition, elite jockeys, unforgettable experiences, and leading entertainment all at iconic venues.<br /> Millions are at stake for horsemen and bettors across more than 50 graded stakes races coast-to-coast, from Gulfstream Park to Santa Anita and culminating at historic Pimlico Race Course. New champions will be crowned at extraordinary racing experiences, only at 1/ST.</p>
            </div>
            <div className='span-3 span-12-mobile c0'></div>
            <div className='span-3 span-12-mobile'></div>
            <div className='span-6 span-9-mobile col-img'>
              <div className='aspect-ratio-16-7'>
                <img src={'/DATA/IMG/JPG/track.jpg'} className={["img-cover", "lazyload", "fade", aboutInView ? "lazyloaded" : null].join(" ")} alt="Track" />
              </div>
            </div>
            <div className='span-3 hero-tour-wrap col-logo'>
              <div className="hero-tour-wrap-intro text-center">
                <div className='hero-logo'>
                  <div className='aspect-ratio-16-5'>
                    <img src={'/DATA/IMG/PNG/racing_tour_logo_dark.png'} className={["img-contain", "lazyload", "fade", aboutInView ? "lazyloaded" : null].join(" ")} alt="Racing tour logo dark" />
                  </div>
                </div>
                <TextReveal size={"h3"} text={"1/ST Racing"} />
                <TextReveal size={"h3"} theme="fancy-font contrast" text={"tour"} />
                <h4>2023</h4>
              </div>
            </div>
          </div>
        </section> */}

      {/* <section ref={refDates} className={`section-dates grid-12 ${datesInView ? 'is-inview' : ''} `} >
          <div className='span-9 span-12-mobile border flex jc-flex-end'>
            <TextReveal size="h2" theme="h1" text={"<div>Jan <span class='arrow-container'></span> May / 23</div>"} />
          </div>
          <div className='span-7 span-12-mobile padd-top padd-bottom-2'>
            <div className='flex-dates'>
              <h4 className='flex-heading animate-in-from-bottom delay-250ms'><span>January 28</span><span>Pegasus World Cup</span></h4>
              <h4 className='flex-heading animate-in-from-bottom delay-375ms'><span>February 4</span><span>Holy Bull & Robert B. Lewis</span></h4>
              <h4 className='flex-heading animate-in-from-bottom delay-500ms'><span>March 4</span><span>Fountain of Youth & Big 'Cap</span></h4>
              <h4 className='flex-heading animate-in-from-bottom delay-625ms'><span>April 1</span><span>Florida Derby</span></h4>
              <h4 className='flex-heading animate-in-from-bottom delay-750ms'><span>April 8</span> <span>Santa Anita Derby</span></h4>
              <h4 className='flex-heading animate-in-from-bottom delay-875ms'><span>May 20</span> <span>Preakness 148</span></h4>
            </div>
          </div>
        </section> */}

    </main >
  );
}

export default Page

export const query = graphql`
          query {
            contentfulTourPage {
              id
              gulfstreamHero {
                key
                value
                value2
                value3
              }
              santaAnitaHero {
                key
                value
                value2
                value3
              }
              pimlicoHero {
                key
                value
                value2
                value3
              }
              about {
                childMarkdownRemark {
                  html
                }
              }
              # gulfstreamPacks {
              #   key
              #   value
              #   value2
              #   value3
              # }
              gulfstreamSingleEvents {
                key
                value
                value2
                value3
              }
              # santaAnitaPacks {
              #   key
              #   value
              #   value2
              #   value3
              # }
              santaAnitaSingleEvents {
                key
                value
                value2
                value3
              }
              pimlicoPacks {
                key
                value
                value2
                value3
              }
              pimlicoSingleEvents {
                key
                value
                value2
                value3
              }
              slidesLeft {
                id
                title
                fluid(maxWidth: 2048, quality: 80) {
                  srcWebp
                  srcSetWebp
                }
              }
              slidesRight {
                id
                title
                fluid(maxWidth: 2048, quality: 80) {
                  srcWebp
                  srcSetWebp
                }
              }
              video {
                id
                title
                file {
                  url
                }
              }
              dates {
                key
                value
                value2
              }
            }
            imgDecor: file(relativePath: {eq: "page-tour/decor.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgFanduelLogo: file(relativePath: {eq: "page-tour/fanduel-logo.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
    #       imgHorseRacing: file(relativePath: {eq: "page-tour/horse-racing.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
          imgLogoGulfBlack: file(relativePath: {eq: "page-tour/logo-gulf-black.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogoGulfWhite: file(relativePath: {eq: "page-tour/logo-gulf-white.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogoPreaknessBlack: file(relativePath: {eq: "page-tour/logo-preakness-black.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogoPreaknessWhite: file(relativePath: {eq: "page-tour/logo-preakness-white.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogoRacingTourWhite: file(relativePath: {eq: "page-tour/logo-racing-tour-white.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogoSapBlack: file(relativePath: {eq: "page-tour/logo-sap-black.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogoSapWhite: file(relativePath: {eq: "page-tour/logo-sap-white.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogosStripWhite: file(relativePath: {eq: "page-tour/logos-strip-white-24.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogosStripBlack: file(relativePath: {eq: "page-tour/logos-strip-black-24.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgMask: file(relativePath: {eq: "page-tour/mask.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgLogoNBC: file(relativePath: {eq: "page-tour/NBC_logo.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
    #       imgParty: file(relativePath: {eq: "page-tour/party.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
          imgPick5: file(relativePath: {eq: "page-tour/pick5.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }

          imgSappromo2: file(relativePath: {eq: "page-tour/sappromo4.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgRacingBG: file(relativePath: {eq: "page-tour/racing-bg.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid
          }
      }
    }
          imgSport: file(relativePath: {eq: "page-tour/sport.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgTabGulf: file(relativePath: {eq: "page-tour/tab-gulf-img.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid
          }
      }
    }
          imgWinner: file(relativePath: {eq: "page-tour/winner.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid
          }
      }
    }
          imgTabGulfLogo: file(relativePath: {eq: "page-tour/tab-gsp-logo.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgTabSap: file(relativePath: {eq: "page-tour/tab-sap-img.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid
          }
      }
    }
          imgTabSapLogo: file(relativePath: {eq: "page-tour/tab-sap-logo.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgTabPimlico: file(relativePath: {eq: "page-tour/tab-pimlico-img.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid
          }
      }
    }
          imgTabPimlicoLogo: file(relativePath: {eq: "page-tour/tab-pimlico-logo.png" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgWageringPhone: file(relativePath: {eq: "page-tour/wagering-phone.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgChampions: file(relativePath: {eq: "page-tour/champions.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
          imgTv: file(relativePath: {eq: "page-tour/tv.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
      }
    }
    #       slideLeft1: file(relativePath: {eq: "page-tour/slide-left-1.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideLeft2: file(relativePath: {eq: "page-tour/slide-left-2.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideLeft3: file(relativePath: {eq: "page-tour/slide-left-3.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideLeft4: file(relativePath: {eq: "page-tour/slide-left-4.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideLeft5: file(relativePath: {eq: "page-tour/slide-left-5.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideRight1: file(relativePath: {eq: "page-tour/slide-right-1.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideRight2: file(relativePath: {eq: "page-tour/slide-right-2.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideRight3: file(relativePath: {eq: "page-tour/slide-right-3.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideRight4: file(relativePath: {eq: "page-tour/slide-right-4.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
    #       slideRight5: file(relativePath: {eq: "page-tour/slide-right-5.jpg" }) {
    #         childImageSharp {
    #         fluid(maxWidth: 2048, quality: 80) {
    #         ...GatsbyImageSharpFluid
    #       }
    #   }
    # }
  }
          `

