import React from "react"
import { useInView } from "react-intersection-observer"
import "./style.scss"

function MarqueeBox(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  const { rows, mobileRows = 10, copy, reverse, classes, speed } = props

  const marqueeBox = []
  let elementsBox = []

  if (typeof window !== 'undefined' && window.innerWidth >= 768) {
    for (let i = 0; i < rows; i++) {
      // let random = Math.floor(Math.random() * 200) + 1;
      elementsBox = []

      for (let j = 0; j < 20; j++) {
        elementsBox.push(
          <span key={j}>
            <span className="copy">
              {copy}
            </span>
            <span style={{ width: speed?.[i] + "px" }} className="separator" />
          </span>
        )
      }

      marqueeBox.push(
        <div key={i} className='line'>
          <div className={`marquee ${reverse ? "reverse" : ""} ${classes}`}>
            <div>
              <div>{elementsBox}</div>
              <div>{elementsBox}</div>
            </div>
          </div>
        </div>
      )
    }
  }
  else {
    for (let i = 0; i < mobileRows; i++) {
      // let random = Math.floor(Math.random() * 200) + 1;
      elementsBox = []

      for (let j = 0; j < 20; j++) {
        elementsBox.push(
          <span key={j}>
            <span className="copy">
              {copy}
            </span>
            <span style={{ width: speed?.[i] + "px" }} className="separator" />
          </span>
        )
      }

      marqueeBox.push(
        <div key={i} className='line'>
          <div className={`marquee ${reverse ? "reverse" : ""} ${classes}`}>
            <div>
              <div>{elementsBox}</div>
              <div>{elementsBox}</div>
            </div>
          </div>
        </div>
      )
    }
  }



  return (
    <div ref={ref} className={`marquee-box ${inView ? "inview-rn" : ""} `}>
      {marqueeBox}
    </div>
  );
}

export default MarqueeBox
